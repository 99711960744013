import React from "react";
import Layout from "../components/layout/Layout";
import { pratichePatenti } from "../data/pratiche-patenti/pratiche-patenti";
import ListFeature from "../components/ListFeature";
import ContactSection from "../components/home-page/ContactSection";
import Seo from "../components/layout/SEO";

const PratichePatenti = () => {
  return (
    <Layout>
      <Seo title="Tutte le Pratiche per Patenti | Autoscuola Faro, Pisa"
        description={"Rinnova la tua patente velocemente, richiedi il duplicato per furto, smarrimento o deterioramento. Scopri tutti i servizi qui."}
        keywords={[
          "rinnovo patente auto Pisa",
          "pratiche patenti",
          "rinnovo patenti Pisa",
          "duplicato patente Pisa",
          "conversione patente estera Pisa",
          "duplicato patente per furto smarrimento",
          "patente internazionale Pisa"
        ]} />
      <ListFeature list={pratichePatenti} />
      <ContactSection />
    </Layout>
  );
};

export default PratichePatenti;
