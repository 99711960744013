import { faArrowsRotate, faCopy, faMap, faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
export const pratichePatenti = {
    title: "Pratiche patenti",
    subtitle: "La tua patente è scaduta o smarrita? Presso la nostra autoscuola a Pisa puoi rinnovare facilmente la tua patente o ottenerne il duplicato, convertire la tua patente estera in quella italiana o ricevere la patente internazionale. Pensiamo noi a fissare gli appuntamenti, le visite mediche e a richiederti i documenti necessari per velocizzare tutti i tempi di attesa",
    list: [
        {
            id: "pat1",
            title: "Rinnovo patente",
            link: "/pratiche-patenti/rinnovo-patente",
            icon: faArrowsRotate,
        },
        {
            id: "pat2",
            title: "Duplicato per smarrimento o furto",
            link: "/pratiche-patenti/duplicato-smarrimento",
            icon: faCopy,
        },
        {
            id: "pat3",
            title: "Duplicato per deterioramento",
            link: "/pratiche-patenti/duplicato-deterioramento",
            icon: faCopy,
        },
        {
            id: "pat4",
            title: "Smarrimento o deterioramento con Rinnovo",
            link: "/pratiche-patenti/smarrimento-deterioramento-rinnovo",
            icon: faArrowsRotate,
        },
        {
            id: "pat5",
            title: "Duplicato per riclassificazione",
            link: "/pratiche-patenti/duplicato-riclassificazione",
            icon: faCopy,
        },
        {
            id: "pat6",
            title: "Conversione patente estera",
            link: "/pratiche-patenti/conversione-patente-estera",
            icon: faMap,
        },
        {
            id: "pat7",
            title: "Patente internazionale",
            link: "/pratiche-patenti/patente-internazionale",
            icon: faEarthAmericas,
        }


    ],
    buttonLink: null,
    buttonText: null
}