import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const ListFeature = ({ list }) => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 pb-48 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-6xl text-4xl font-roboto font-bold tracking-tight text-gray-900 text-center title-font  mb-4">
            {list.title}
          </h1>
          <h2 className="text-2xl font-sans tracking-tight text-gray-900 leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            {list.subtitle}
          </h2>
        </div>
        <div className="flex flex-wrap lg:w-3/5 sm:mx-auto sm:mb-2 -mx-2">
          {list.list.map((el) => (
            <div className="p-2 sm:w-1/2 w-full">
              <div className="align-middlebg-gray-100 rounded flex p-4 h-full items-center content-center">
                <Link
                  key={el.id}
                  href={el.link}
                  className="hover:bg-blue-100 border-blue-600 w-full p-3 flex sm:items-start items-center rounded-lg hover:bg-gray-50"
                >
                  <FontAwesomeIcon
                    icon={el.icon}
                    className="pt-2 fa-solid flex-shrink-0 mx-4 h-10 w-10 sm:text-2xl text-xl font-roboto font-bold tracking-tight text-blue-accent-700"
                  />
                  <span className="sm:text-2xl text-xl font-roboto font-bold tracking-tight text-blue-accent-700 title-font mt-2">{el.title}</span>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {list.buttonText !== null ?
          <Link href={list.buttonLink}>
            <button
              className={`flex mx-auto mt-16 text-white bg-blue-accent-400 border-0 py-2 px-8 focus:outline-none hover:bg-blue-accent-700 rounded text-xl`}
            >
              {list.buttonText}
            </button>
          </Link>
          :
          null
        }

        
      </div>
    </section>
  );
};

ListFeature.defaultProps = {
  theme: 'indigo',
};

ListFeature.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ListFeature;
