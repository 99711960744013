import React, { useRef, useState } from "react";
import { Modal } from "../Modal";
import { ErrorModal } from "../ErrorModal";
import emailjs from "@emailjs/browser";
import Macbook from "../../../static/patente-nautica/ormeggio.jpg";

const ContactSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uuz9e3b",
        "template_vbita4h",
        form.current,
        "_uQ01AzQbXOfXxhng"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setOpenModal(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          setOpenErrorModal(true);
          e.target.reset();
        }
      );
  };

  return (
    <div className="relative">
      <Modal open={openModal} closeModal={() => setOpenModal(false)} />
      <ErrorModal
        open={openErrorModal}
        closeModal={() => setOpenErrorModal(false)}
      />
      <img
        src={Macbook}
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative bg-gray-800 bg-opacity-80">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="mb-6 font-sans text-3xl font-extrabold tracking-tight text-white sm:text-6xl sm:leading-none">
              Vuoi saperne di più?
              <br className="hidden md:block" />{" "}
              <span className="relative inline-block">
                Contattaci!
                <div className="w-full h-3 -mt-3 bg-blue-accent-400" />
              </span>
            </h2>
            <p className="mb-6 text-lg font-thin tracking-wide text-gray-300 md:text-xl">
              Lascia la tua email per ricevere informazioni aggiuntive sulla
              nostra autoscuola!
            </p>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16"
            >
              <input
                placeholder="Email"
                id="email"
                name="user_email"
                required
                type="text"
                className="flex-grow w-full h-12 px-4 mb-3 text-black transition duration-200 bg-transparent border-2 border-gray-400 rounded appearance-none md:mr-2 md:mb-0 focus:border-blue-accent-200 focus:outline-none focus:shadow-outline"
              />
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-blue-accent-400 hover:bg-blue-accent-700 focus:shadow-outline focus:outline-none"
              >
                Invia
              </button>
            </form>
            <p className="max-w-md mb-10 text-xs font-thin tracking-wide text-gray-200 sm:text-sm sm:mx-auto md:mb-16">
              Rispettiamo la tua privacy. Leggi la nostra{" "}
              <a href="/privacy-policy">Privacy Policy.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
